<template>
  <div
    v-if="isButtonLinkValid"
    class="read-more"
    :class="{ 'read-more--dark': isDark }"
  >
    <T3Link
      :to="link ?? button?.link"
      class="read-more__link"
    >
      <span @click="onButtonClick">
        <span>{{ (content ?? button?.content) || defaultContent }}</span>

        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          class="read-more__arrow"
        >
          <path
            d="M1.15817 0.183105L0.0771484 1.26413L3.58856 4.78321L0.0771484 8.30228L1.15817 9.38331L5.75827 4.78321L1.15817 0.183105Z"
          />
        </svg>
      </span>
    </T3Link>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { isLinkValid } from '~ui/helpers/linkUtils'
import type { UiButtonProps } from '~ui/types'

type ReadMoreProps = Omit<UiButtonProps, 'isArrow'>

const props = defineProps<
  {
    button?: ReadMoreProps
    lang?: string
  } & ReadMoreProps
>()

const defaultText = {
  en: 'Read more',
  pl: 'Dowiedz się więcej',
  de: 'Weiterlesen'
} as const

const defaultContent = computed(() => {
  return defaultText[(props.lang as keyof typeof defaultText) ?? 'en']
})

const isDark = computed(() =>
  ['solid-black'].includes(props.variant ?? props.button?.variant ?? '')
)

const isButtonLinkValid = computed(() =>
  isLinkValid(props.link ?? props.button?.link)
)

const emit = defineEmits(['click'])
const onButtonClick = () => {
  emit('click')
}
</script>

<style lang="scss">
.read-more {
  font-weight: 600;

  $base: &;

  & > &__link {
    padding: em(5px);
    padding-left: 0;
    color: color(primary);

    &:is(:hover, :active, :focus) {
      color: color(black);

      & #{$base}__arrow {
        transform: translateX(8px);
      }
    }

    & #{$base}__arrow {
      color: inherit;
      fill: currentColor;
      margin-left: 5px;
      transition: transform 0.25s ease-in-out;
    }
  }

  &--dark {
    font-weight: 500;
    display: inline-block;

    & > #{$base}__link {
      color: color(black);
    }

    &::after {
      content: '';
      display: block;
      height: 0;
      width: calc(100% - 15px);
      border-bottom: 2px solid color(primary);
    }
  }
}
</style>
